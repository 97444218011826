"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ height: "100%", width: "100%", viewBox: "0 0 36 36", fill: "url(#gradient)" }, { children: [(0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsxs)("linearGradient", __assign({ x1: "50%", x2: "50%", y1: "97.0782153%", y2: "0%", id: "gradient" }, { children: [(0, jsx_runtime_1.jsx)("stop", { offset: "0%", stopColor: "#0062E0" }), (0, jsx_runtime_1.jsx)("stop", { offset: "100%", stopColor: "#19AFFF" })] })) }), (0, jsx_runtime_1.jsx)("path", { d: "M15 35.8C6.5 34.3 0 26.9 0 18 0 8.1 8.1 0 18 0s18 8.1 18 18c0 8.9-6.5 16.3-15 17.8l-1-.8h-4l-1 .8z" }), (0, jsx_runtime_1.jsx)("path", { fill: "white", d: "M25 23l.8-5H21v-3.5c0-1.4.5-2.5 2.7-2.5H26V7.4c-1.3-.2-2.7-.4-4-.4-4.1 0-7 2.5-7 7v4h-4.5v5H15v12.7c1 .2 2 .3 3 .3s2-.1 3-.3V23h4z" })] })));
}
exports.default = Svg;
