"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", viewBox: "0 0 9 8" }, props, { children: [(0, jsx_runtime_1.jsx)("style", { children: ".o{fill:#fc0}.r{fill:red}" }), (0, jsx_runtime_1.jsx)("path", { d: "M0,7 h1 v1 h-1 z", className: "r" }), (0, jsx_runtime_1.jsx)("path", { d: "M0,0 h1 v7 h-1 z", className: "o" }), (0, jsx_runtime_1.jsx)("path", { d: "M2,0 h1 v8 h-1 z", className: "o" }), (0, jsx_runtime_1.jsx)("path", { d: "M4,0 h1 v8 h-1 z", className: "o" }), (0, jsx_runtime_1.jsx)("path", { d: "M6,0 h1 v8 h-1 z", className: "o" }), (0, jsx_runtime_1.jsx)("path", { d: "M8,3.25 h1 v1.5 h-1 z", className: "o" })] })));
}
exports.default = Svg;
