"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.allSourcesMap = exports.allSources = void 0;
var singer_1 = require("./singer");
var native_1 = require("./native");
var helper_1 = require("./helper");
var airbyte_helper_1 = require("./airbyte.helper");
var lodash_1 = require("lodash");
var airbyte_1 = require("./airbyte");
var sdk_source_1 = require("./sdk_source");
var sdk_source_helper_1 = require("./sdk_source.helper");
exports.allSources = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], native_1.allNativeConnectors, true), sdk_source_1.allSdkSources.map(sdk_source_helper_1.makeSdkSource), true), singer_1.allSingerTaps.filter(function (tap) { return !tap.hasNativeEquivalent && tap.pic && tap.stable; }).map(helper_1.makeSingerSource), true), airbyte_1.allAirbyteSources.filter(function (as) { return !as.hasNativeEquivalent; }).map(airbyte_helper_1.makeAirbyteSource), true);
exports.allSourcesMap = exports.allSources.reduce(function (accumulator, current) {
    var _a;
    return (__assign(__assign({}, accumulator), (_a = {}, _a[(0, lodash_1.snakeCase)(current.id)] = current, _a)));
}, {});
__exportStar(require("./sdk_source.helper"), exports);
__exportStar(require("./airbyte.helper"), exports);
