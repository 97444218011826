"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 1 1", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M0.782522 0.529408C0.662399 0.529408 0.565037 0.634747 0.565037 0.764696C0.565037 0.894652 0.662399 1 0.782522 1C0.902631 1 1 0.894652 1 0.764696C1 0.634747 0.902631 0.529408 0.782522 0.529408ZM0.217482 0.529424C0.0973733 0.529424 0 0.634747 0 0.764708C0 0.894652 0.0973733 1 0.217482 1C0.337597 1 0.434974 0.894652 0.434974 0.764708C0.434974 0.634747 0.337597 0.529424 0.217482 0.529424ZM0.717476 0.235288C0.717476 0.365236 0.620114 0.4706 0.500006 0.4706C0.379886 0.4706 0.28252 0.365236 0.28252 0.235288C0.28252 0.105348 0.379886 0 0.500006 0C0.620114 0 0.717476 0.105348 0.717476 0.235288Z", fill: "url(#paint0_radial)" }), (0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsxs)("radialGradient", __assign({ id: "paint0_radial", cx: "0", cy: "0", r: "1", gradientUnits: "userSpaceOnUse", gradientTransform: "translate(0.5 0.546522) scale(0.662633)" }, { children: [(0, jsx_runtime_1.jsx)("stop", { stopColor: "#FFB900" }), (0, jsx_runtime_1.jsx)("stop", { offset: "0.6", stopColor: "#F95D8F" }), (0, jsx_runtime_1.jsx)("stop", { offset: "0.9991", stopColor: "#F95353" })] })) })] })));
}
exports.default = Svg;
