"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", version: "1.1", id: "svg2", xmlSpace: "preserve", width: "100%", height: "100%", viewBox: "0 0 533.33331 533.33331" }, props, { children: (0, jsx_runtime_1.jsx)("g", __assign({ id: "g10", transform: "matrix(1.3333333,0,0,-1.3333333,0,533.33333)" }, { children: (0, jsx_runtime_1.jsxs)("g", __assign({ id: "g12", transform: "scale(0.1)" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M 4000,2000 C 4000,895.441 3104.57,0 2000,0 895.434,0 0,895.441 0,2000 c 0,1104.57 895.434,2000 2000,2000 1104.57,0 2000,-895.43 2000,-2000 z m -1640.79,348.21 c -238.03,0 -365.64,-81.7 -455.12,-170.52 l -24.4,-25.61 -0.07,797.74 h -205.63 v -1305.6 c 0,-401.74 309.45,-652.048 664.52,-652.048 391.16,0 687.28,301.018 687.28,687.968 0,359.28 -308.87,668.07 -666.58,668.07 z M 1577,2083.27 l -91.26,299.05 c -54.47,157.29 -70.59,247.83 -224.06,409.88 -52.56,55.59 -251.53,177.92 -226.83,151.23 252.14,-272.17 381.09,-601.96 430.83,-693.56 -45.68,55.47 -99.23,105.28 -160.31,167.24 -52.02,52.76 -106.35,100.36 -158,132.26 -33.97,21.05 -53.05,32.65 -87.52,50.5 -155.346,80.7 -305.932,129.55 -363.745,143.98 -70.601,17.58 -140.203,22.17 -140.203,22.17 0,0 225.118,-190.05 321.547,-311.03 96.469,-120.92 194.871,-229.36 303.651,-273.69 108.77,-44.35 146.62,-57.05 232.15,-70 73.77,-11.12 163.75,-28.03 163.75,-28.03 z m 761.51,-903.69 c -258.29,0 -476.87,203.66 -476.87,476.44 0,272.94 184.3,497.1 481.5,497.1 297.27,0 472.4,-240.33 472.4,-492.06 0,-273.86 -185.33,-481.48 -477.03,-481.48", style: {
                            fill: "#69c52f",
                            fillOpacity: 1,
                            fillRule: "evenodd",
                            stroke: "none",
                        }, id: "path14" }), (0, jsx_runtime_1.jsx)("path", { d: "m 3748.36,395.941 c 1.08,-2.519 2.11,-4.91 3.1,-7.16 0.99,-2.242 1.84,-4.621 2.56,-7.14 0.91,2.519 1.71,4.898 2.43,7.14 0.72,2.25 1.8,4.641 3.25,7.16 l 45.62,81.797 c 1.61,2.344 3.15,3.883 4.58,4.602 1.45,0.711 3.6,1.07 6.49,1.07 H 3845 V 323.59 h -29.7 v 98.531 l 2.43,20.258 -49.4,-90.707 c -2.34,-5.043 -6.29,-7.563 -11.88,-7.563 h -4.86 c -5.58,0 -9.44,2.52 -11.61,7.563 l -49.67,89.898 2.16,-19.449 V 323.59 h -29.69 v 159.82 h 28.61 c 3.06,0 5.22,-0.359 6.48,-1.07 1.26,-0.719 2.87,-2.258 4.85,-4.602 z m -111.5,87.469 v -28.351 h -48.6 V 323.59 h -34.01 v 131.469 h -48.6 v 28.351 h 131.21", style: {
                            fill: "#69c52f",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                        }, id: "path16" })] })) })) })));
}
exports.default = Svg;
