"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeSingerSource = exports.customParameters = exports.prefixParameters = exports.singerConfigParams = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("../types");
exports.singerConfigParams = {
    catalogJson: function (tap) {
        return {
            displayName: "Singer Catalog JSON",
            id: "catalog",
            type: types_1.jsonType,
            required: true,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Singer", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://github.com/singer-io/getting-started/blob/master/docs/SPEC.md#catalog" }, { children: "catalog" })), " ", "that defines data layout.", " ", (0, jsx_runtime_1.jsxs)("a", __assign({ href: "https://github.com/singer-io/".concat(tap) }, { children: ["Read catalog documentation for ", tap] }))] })),
            defaultValue: {},
        };
    },
    stateJson: function (tap) {
        return {
            displayName: "Singer Initial State JSON",
            id: "state",
            type: types_1.jsonType,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Singer initial", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://github.com/singer-io/getting-started/blob/master/docs/SPEC.md#state" }, { children: "state" })), ". For most cases should be empty", (0, jsx_runtime_1.jsxs)("a", __assign({ href: "https://github.com/singer-io/".concat(tap) }, { children: ["Read documentation for ", tap] }))] })),
            defaultValue: {},
        };
    },
    propertiesJson: function (tap) {
        return {
            displayName: "Singer Properties JSON",
            id: "properties",
            type: types_1.jsonType,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Singer properties that defines resulting schema.", " ", (0, jsx_runtime_1.jsxs)("a", __assign({ href: "https://github.com/singer-io/".concat(tap) }, { children: ["Read documentation for ", tap] }))] })),
            defaultValue: {},
        };
    },
    configJson: function (tap) {
        return {
            displayName: "Singer Config JSON",
            id: "config",
            type: types_1.jsonType,
            required: true,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Singer", " ", (0, jsx_runtime_1.jsx)("a", __assign({ href: "https://github.com/singer-io/getting-started/blob/master/docs/SPEC.md#state" }, { children: "configuration" })), ".", " ", (0, jsx_runtime_1.jsxs)("a", __assign({ href: "https://github.com/singer-io/".concat(tap) }, { children: ["Read documentation for ", tap] }))] })),
            defaultValue: {},
        };
    },
};
/**
 * Prefix each parameter id with given prefix
 */
function prefixParameters(prefix, parameters) {
    return parameters.map(function (p) {
        return __assign(__assign({}, p), { id: prefix + p.id });
    });
}
exports.prefixParameters = prefixParameters;
/**
 * Customizes parameters for singer tap.
 */
function customParameters(tap, params) {
    return __spreadArray([], (params.customConfig
        ? prefixParameters("config.", params.customConfig)
        : [exports.singerConfigParams.customConfig(tap)]), true);
}
exports.customParameters = customParameters;
/**
 * Update Parameter.id field to make its pattern similar to destination Parameters
 * */
var fixConfigParamsPath = function (params) {
    return params.map(function (p) { return (__assign(__assign({}, p), { id: "config.".concat(p.id) })); });
};
/**
 * Not a common Source connector.
 */
var makeSingerSource = function (singerTap) {
    var _a;
    return {
        protoType: "singer",
        expertMode: !singerTap.parameters,
        pic: singerTap.pic,
        displayName: singerTap.displayName,
        id: "singer-".concat(singerTap.tap),
        collectionTypes: [],
        documentation: singerTap.documentation,
        collectionParameters: [],
        deprecated: singerTap.deprecated,
        configParameters: __spreadArray([
            {
                displayName: "Singer Tap",
                id: "config.tap",
                type: types_1.stringType,
                required: true,
                documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Id of Singer Tap" }),
                constant: singerTap.tap,
            }
        ], fixConfigParamsPath((_a = singerTap.parameters) !== null && _a !== void 0 ? _a : [exports.singerConfigParams.configJson(singerTap.tap)]), true),
    };
};
exports.makeSingerSource = makeSingerSource;
